import { gsap } from "gsap/dist/gsap";
    
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

"use strict";

gsap.registerPlugin(ScrollTrigger);

// what we do animation section pin
const sections = gsap.utils.toArray(".all-services-area .service-item:not(:last-child)");
const sectionCount = sections.length;

let mm = gsap.matchMedia();

gsap.set(".all-services-area .service-item", { 
	zIndex: (i, target, targets) => targets.length + i 
});

mm.add("(min-width: 991px)", () => {
	sections.forEach((section, i) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: section,
				start: 'center 55%',
				end: 'bottom top', 
				scrub: true,
				markers: false,
				pin: true,
			},
		});

		tl.to(section, { 
			opacity: 0, 
			scale: 0.8,
			delay: 0.05
		});
	});
});

// Video for mobile & desktop
const match = window.matchMedia("(max-width: 991px)");
const desktopMobileVideo = document.querySelectorAll('.desktop-mobile-video-item');
desktopMobileVideo.forEach((video) => {
	let source = document.createElement("source");
	let videoUrl = video.dataset.video;
	let videoMobileUrl = video.dataset.mobilevideo;
	source.setAttribute("type", "video/mp4");
	video.appendChild(source);	
	// console.log(videoMobileUrl)
	mm.add("(max-width: 991px)", () => {
		video.pause();
		source.removeAttribute("src");
		source.setAttribute("src", videoMobileUrl);
		video.load();
		video.play();
	});
	mm.add("(min-width: 991px)", () => {
		video.pause();
		source.removeAttribute("src");
		source.setAttribute("src", videoUrl);
		video.load();
		video.play();
	});
});

// Video portfolio autoplay video
const videoPortfolio = document.querySelectorAll('.video-portfolio-item .video-portfolio-video video');

videoPortfolio.forEach((video) => {
	let tl2 = gsap.timeline({
		scrollTrigger: {
			trigger: video,
			start: 'bottom bottom',
			end: 'bottom 50%', 
			markers: false,
			onEnter: () => video.play(),  // Play video on enter
            onLeave: () => video.pause(), // Pause video on leave
            onEnterBack: () => video.play(), // Resume play when scrolling back
            onLeaveBack: () => video.pause()  // Pause when leaving backwards
		},
	})

});


// Horizontal number tap section on mobile
const horizontalTapItems = document.querySelectorAll('.horizontal-number-taps-wrap .horizontal-number-tap-item');

// const mobileHorizontalTapOpen = (element) => {
//     $(element).find('.horizontal-number-tap-content-area').slideDown(400, () => {
// 		ScrollTrigger.refresh()
// 	});
// };
mm.add("(max-width: 991px)", () => {
	horizontalTapItems.forEach((horizontalTapItem) => {
		let tl3 = gsap.timeline({
			scrollTrigger: {
				trigger: horizontalTapItem,
				start: 'center 50%',
				markers: false,
				onEnter: () => {
					$(horizontalTapItem).find('.horizontal-number-tap-content-area').slideDown(400, () => {
						ScrollTrigger.refresh()
					});
				},
			},
		});
	});
});