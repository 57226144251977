import { gsap } from "gsap/dist/gsap";
    
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

"use strict";

gsap.registerPlugin(ScrollTrigger);

// what we do animation section pin
const sections = gsap.utils.toArray(".all-services-area .service-item:not(:last-child)");
const sectionCount = sections.length;

let mm = gsap.matchMedia();

gsap.set(".all-services-area .service-item", { 
	zIndex: (i, target, targets) => targets.length + i 
});

mm.add("(min-width: 991px)", () => {
	sections.forEach((section, i) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: section,
				start: 'center 55%',
				end: 'bottom top', 
				scrub: true,
				markers: false,
				pin: true,
			},
		});

		tl.to(section, { 
			opacity: 0, 
			scale: 0.8,
			delay: 0.05
		});
	});
});

// Video for mobile & desktop
const match = window.matchMedia("(max-width: 991px)");
const desktopMobileVideo = document.querySelectorAll('.desktop-mobile-video-item');
desktopMobileVideo.forEach((video) => {
	let source = document.createElement("source");
	let videoUrl = video.dataset.video;
	let videoMobileUrl = video.dataset.mobilevideo;
	source.setAttribute("type", "video/mp4");
	video.appendChild(source);	
	// console.log(videoMobileUrl)
	mm.add("(max-width: 991px)", () => {
		video.pause();
		source.removeAttribute("src");
		source.setAttribute("src", videoMobileUrl);
		video.load();
		video.play();
	});
	mm.add("(min-width: 991px)", () => {
		video.pause();
		source.removeAttribute("src");
		source.setAttribute("src", videoUrl);
		video.load();
		video.play();
	});
});

// Video portfolio autoplay video
const videoPortfolio = document.querySelectorAll('.video-portfolio-item .video-portfolio-video video');

videoPortfolio.forEach((video) => {
	let tl2 = gsap.timeline({
		scrollTrigger: {
			trigger: video,
			start: 'bottom bottom',
			end: 'bottom 50%', 
			markers: false,
			onEnter: () => video.play(),  // Play video on enter
            onLeave: () => video.pause(), // Pause video on leave
            onEnterBack: () => video.play(), // Resume play when scrolling back
            onLeaveBack: () => video.pause()  // Pause when leaving backwards
		},
	})

});




// Horizontal number tap section
const checkHeight = () => {
	$('.horizontal-number-taps-wrap').each((index, element) => {
		let thisHorizontalTap = $(element);
		let horizontalheight = 0;
		thisHorizontalTap.find('.horizontal-number-tap-item').each((index, element) => {
			if($(element).hasClass('active')){
				horizontalheight = $(element).find('.horizontal-number-tap-content-area').height();
				$(element).css({
					'padding-bottom': horizontalheight + 'px'
				});
			} else {
				setTimeout(() => {
					$(element).css({
						'padding-bottom': 0
					});
				}, 250);
			}
		});
	});
}

mm.add("(min-width: 992px)", () => {
	$('.horizontal-number-taps-wrap').each((index, element) => {
		$(element).find('.horizontal-number-tap-icon-area').on('click', (event) => {
			const currentElement = $(event.currentTarget).closest('.horizontal-number-tap-item');
			$(element).find('.horizontal-number-tap-item').not(currentElement).removeClass('active').find('.horizontal-number-tap-content-area').slideUp();
			currentElement.toggleClass('active');
			currentElement.find('.horizontal-number-tap-content-area').slideToggle();
		});
	});
	
	checkHeight();

	$('.horizontal-number-taps-wrap').each((index, element) => {
		$(element).find('.horizontal-number-tap-icon-area').on('click', (event) => {
			checkHeight();
		});
	});
});

const horizontalTapItems = document.querySelectorAll('.horizontal-number-taps-wrap .horizontal-number-tap-item');
mm.add("(max-width: 991px)", () => {
	horizontalTapItems.forEach((horizontalTapItem) => {
		let tl3 = gsap.timeline({
			scrollTrigger: {
				trigger: horizontalTapItem,
				start: 'center 50%',
				markers: false,
				onEnter: () => {
					$(horizontalTapItem).addClass('active');
					$(horizontalTapItem).find('.horizontal-number-tap-content-area').slideDown(400, () => {
						ScrollTrigger.refresh()
					});
				},
			},
		});
	});
});